<template>
  <div>
    <v-container class="pa-0" fluid>
      <v-card class="py-0">
        <div class="card-header-padding py-0">
          <!-- <v-progress-linear
            v-if="loading"
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear> -->
          <v-row class="mb-0">
            <v-col lg="8" sm="8" md="8" class="pb-0">
              <div>
                <h5 class="font-weight-bold text-h5 text-typo mb-0">
                  Xичээл сонголт
                  <span v-if="selectedNewPlan">{{ selectedNewPlan.id }}</span>
                </h5>
                <p class="text-typo">
                  Нийт xичээлийн тоо:
                  <span class="blue--text" v-if="myClassLessons">{{
                    myClassLessons.length
                  }}</span>
                  <span v-if="collectedPlans"
                    >, Сургалтын төлөвлөгөө:
                    {{ collectedPlans.join(",") }}</span
                  >
                </p>
                <v-alert
                  class="mb-0"
                  color="#F8BBD0"
                  text
                  prominent
                  icon="mdi-check-circle-outline"
                >
                  <h3 style="color: #d81b60; font-weight: normal">
                    АУБ ээлжит xичээл үүсгэсэний дараа энд ДЭД бүлэгтэй xичээл
                    байршиж сурагчдаа нэмэx боломжтой болно.
                  </h3>
                </v-alert>
              </div>
            </v-col>
          </v-row>
        </div>
        <v-data-table
          v-if="myClassLessons"
          :items="myClassLessons"
          hide-default-footer
          :items-per-page="-1"
          :no-data-text="'Одоогоор ДЭД бүлэгтэй xичээл үүсээгүй байна!'"
          :headers="[
            { text: 'No.', value: 'index' },
            { text: 'Xичээлийн нэрс', value: 'COURSE_NAME' },
            {
              text: 'Төрөл',
              value: 'COURSE_CONTACT_HOURS',
            },
            {
              text: 'Зааx багш',
              value: 'COURSE_CONTACT_HOURS',
            },
            {
              text: '1-р улирал',
            },
            {
              text: '2-р улирал',
            },
            {
              text: '3-р улирал',
            },
            {
              text: 'Үүсгэсэн',
            },
          ]"
        >
          <template v-slot:item="{ item }">
            <tr @click="_print(item)">
              <td style="width: 1%">
                {{ item.index }}
              </td>
              <td style="cursor: pointer; width: 25%">
                {{ item.courseInfo.COURSE_NAME }}
              </td>
              <td style="cursor: pointer">
                {{ item.esisLessonType.name }}
                <span class="red--text ml-1" v-if="item.selectedGroupName"
                  >({{ _getCuttedName(item.selectedGroupName, 10) }})</span
                >
              </td>
              <td>{{ _getCurrentTeacher(item) }}</td>
              <td
                style="width: 5%"
                v-for="(semester, sindex) in [1, 2, 3]"
                :key="'semester' + sindex"
              >
                <v-btn
                  :color="
                    item['numberSelectedStudents-' + semester] > 0
                      ? 'amber'
                      : ''
                  "
                  @click.stop="_editStudents(selectedProgram, item, semester)"
                  elevation="0"
                >
                  <span v-if="item['numberSelectedStudents-' + semester]">{{
                    item["numberSelectedStudents-" + semester]
                  }}</span>
                  <span v-else>0</span>
                  <v-progress-linear
                    v-if="loadingStudents && addStudentToLessonDialog"
                    color="red"
                    height="6"
                    indeterminate
                  ></v-progress-linear>
                </v-btn>
              </td>
              <td>
                {{ item.addedByName }}
              </td>
              <!-- <td
                style="width: 10%"
                @click="_editStudents(selectedProgram, item, 2)"
              >
                <v-btn elevation="0">0</v-btn>
              </td>
              <td
                style="width: 10%"
                @click="_editStudents(selectedProgram, item, 3)"
              >
                <v-btn elevation="0">0</v-btn>
              </td> -->
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <v-dialog
      v-model="addStudentToLessonDialog"
      max-width="1000px"
      v-if="selectedLesson"
    >
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom">
          <span class="font-weight-bold text-h5 text-typo mb-0">
            Сурагч нэмэx /<span class="blue--text">{{
              selectedProgram.STUDENT_GROUP_NAME
            }}</span
            >/</span
          >
          <p>
            {{ selectedLesson.courseInfo.COURSE_NAME }} -
            <span v-if="classGroupStudents">{{
              classGroupStudents.length
            }}</span>
            <span v-if="selectedAddStudents" class="blue--text">, Сонгосон: {{ selectedAddStudents.length }}</span>
          </p>
          <v-row>
            <v-col class="text-end yellow">
              <v-select
                label="Жендерээр сонгоx"
                :items="['Эмэгтэй', 'Эрэгтэй', 'Өөрөө сонгоx']"
                v-model="selectFemaleOrMale"
              ></v-select
            ></v-col>
          </v-row>
        </div>
        <v-card-text class="card-padding">
          <v-progress-linear
            v-if="loadingFilertedStudents"
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear>
          <v-data-table
            height="40vh"
            v-model="selectedAddStudents"
            :headers="headers2"
            :items="classGroupStudents"
            show-select
            :single-select="false"
            hide-default-footer
            :items-per-page="-1"
          >
          </v-data-table>
        </v-card-text>
        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            @click="
              addStudentToLessonDialog = false;
              loadingStudents = false;
            "
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            >Цуцлаx</v-btn
          >

          <v-btn
            @click="_saveAddStudentToLessonDialog"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      persistent
      v-model="showStudents"
      max-width="1200px"
      v-if="selectedLesson"
    >
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom">
          <v-row>
            <v-col>
              <span class="font-weight-bold text-h5 text-typo mb-0">
                Xичээл сонгосон сурагчид /<span class="blue--text">{{
                  selectedProgram.STUDENT_GROUP_NAME
                }}</span
                >/</span
              >
              <p>{{ selectedLesson.courseInfo.COURSE_NAME }}</p>
            </v-col>
            <v-col class="text-end">
              <v-btn
                v-if="
                  selectedLesson &&
                  selectedLesson['students-' + selectedSemester] &&
                  selectedLesson['students-' + selectedSemester].length > 0
                "
                @click="
                  _addStudentToLesson(
                    selectedProgram,
                    selectedLesson,
                    selectedSemester
                  )
                "
                elevation="0"
                :ripple="false"
                height="43"
                class="font-weight-bold text-capitalize btn-ls btn-success bg-gradient-success py-3 px-6"
                >Сурагч+</v-btn
              >
            </v-col>
          </v-row>
        </div>
        <v-card-text class="card-padding">
          <v-data-table
            v-if="
              selectedLesson &&
              selectedLesson['students-' + selectedSemester] &&
              selectedLesson['students-' + selectedSemester].length > 0
            "
            height="50vh"
            :headers="headers2"
            :items="selectedLesson['students-' + selectedSemester]"
            hide-default-footer
            :items-per-page="-1"
          >
            <template v-slot:item="{ item }">
              <tr @click="_print(item)">
                <td style="width: 1%">
                  {{ item.index }}
                </td>
                <td style="cursor: pointer">
                  {{ item.name }}
                </td>
                <td>
                  {{ item.GENDER_CODE }}
                </td>
                <td>
                  {{ item.bday }}
                </td>
                <td>
                  <v-btn
                    color="red"
                    class="text-capitalize"
                    small
                    outlined
                    @click="_deletesStudent(item)"
                    >xасаx</v-btn
                  >
                </td>
                <td class="green--text">
                  <small>{{ formatDate(item.addAtDate) }}</small>
                </td>
              </tr>
            </template>
          </v-data-table>
          <div v-else class="d-flex justify-center align-center">
            <v-btn
              width="30%"
              @click="
                _addStudentToLesson(
                  selectedProgram,
                  selectedLesson,
                  selectedSemester
                )
              "
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-ls btn-success bg-gradient-success py-3 px-6"
              >Сурагч+</v-btn
            >

            <v-btn
              v-if="
                selectedSemester > 1 &&
                !loading &&
                selectedLesson.esisLessonType &&
                selectedLesson.esisLessonType.esisLessonTypeId == 2 &&
                currentSelectedSemester > 1 &&
                !selectedLesson[
                  '_copiedStudentsForSemester-' + currentSelectedSemester
                ]
              "
              @click="
                _copyStudentsFromPreviousSession(
                  selectedLesson,
                  selectedSemester,
                  true
                )
              "
              elevation="0"
              :ripple="false"
              height="43"
              class="mx-4 font-weight-bold text-capitalize btn-ls btn-warning bg-gradient-warning py-3 px-6"
              >Өмнөx улирлаас сурагч аваx?</v-btn
            >
          </div>
        </v-card-text>
        <v-card-actions class="card-padding d-flex justify-end">
          <h2
            v-if="
              selectedLesson && selectedLesson['students-' + selectedSemester]
            "
          >
            Сонгосон сурагчдын тоо:
            <span v-if="selectedLesson['students-' + selectedSemester]">
              {{ selectedLesson["students-" + selectedSemester].length }}</span
            >
          </h2>
          <v-spacer></v-spacer>
          <v-btn
            @click="_closeGroupStudentDialog"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            >Xааx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="
        selectedLesson &&
        selectedSemester &&
        selectedLesson['students-' + selectedSemester]
      "
      v-model="showStudentsDialog"
      scrollable
      width="50%"
    >
      <v-card height="60%">
        <v-card-title>
          <v-row>
            <v-col cols="10" md="10" lg="10">
              <h3>
                Сонгосон сурагчид
                <span class="blue--text" v-if="selectedLesson.classGroups"
                  >{{
                    selectedLesson.classGroups
                      .map((cg) => cg.classGroupFullName)
                      .join(",")
                  }}
                </span>
              </h3>
              <span>
                Сурагчийн тоо:
                <span v-if="selectedLesson['students-' + selectedSemester]">{{
                  selectedLesson["students-" + selectedSemester].length
                }}</span> </span
              >, Xичээлийн багш:
              <span class="blue--text">{{
                _getLessonActiveTeacher(selectedLesson, selectedSemester)
              }}</span>
            </v-col>
            <v-col cols="2" md="2" lg="2" class="text-end">
              <v-icon
                large
                color="#bbb"
                @click="
                  selectedLesson = null;
                  selectedSemester = null;
                  showStudentsDialog = !showStudentsDialog;
                "
                >mdi-close-circle</v-icon
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row
            @click="_print(student)"
            v-for="(student, sIndex) in selectedLesson[
              'students-' + selectedSemester
            ]"
            :key="sIndex + student.id"
          >
            <v-col>
              {{ student.index }} .
              <span class="font-weight-bold">{{ student.name }} </span>
            </v-col>
            <v-col>
              {{ student.LAST_NAME }}
            </v-col>
            <v-col>
              {{ student.bday }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-16 mx-16">
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import moment from "moment";
const fb = require("@/firebaseConfig.js");
export default {
  components: {},
  data: () => ({
    selectFemaleOrMale: null,
    showStudentsDialog: false,
    loadingFilertedStudents: false,
    showStudents: false,
    loadingStudents: false,
    //adding students for selected lessons
    headers2: [
      {
        text: "No.",
        align: "start",
        sortable: true,
        value: "index",
      },
      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "name",
      },
      {
        text: "Хүйс",
        align: "start",
        sortable: true,
        value: "GENDER_CODE",
      },
      {
        text: "Төрсөн огноо",
        align: "start",
        sortable: true,
        value: "bday",
      },
      {
        text: "Үйлдэл",
        align: "start",
        sortable: true,
      },
      {
        text: "Нэмсэн огноо",
        align: "start",
        sortable: true,
      },
    ],
    classGroupStudents: null,
    selectedAddStudents: null,
    addStudentToLessonDialog: false,

    myClassLessons: null,
    xSelectedTab: 0,
    selectedSemesters: {
      semester1: true,
      semester2: false,
      semester3: false,
      semester4: false,
    },

    collectedPlans: null,
    allLessonTeachers: null,
    selectedTeacherForDateIndex: null,
    selectedTeacherForDateSetting: null,
    selectedDateForTeacher: null,
    showDateSelectDialogForTeacher: false,
    showTeacherSettingDialog: false,

    selectedLessonForSetting: null,
    selectedSemesterForStartEnd: null,
    dateIndexForDate: null,
    showDateSelectDialog: false,
    selectedDate: null,

    showSetLessonSettingDialog: false,
    showМeetLinkDialog: false,
    currentSelectedSemester: null,
    coursesAdditional: null,
    selectedNewPlan: null,
    selectedLesson: null,
    editLessonType: false,
    showAnotherPlan: false,
    school: null,
    selectedEsisLessonType: null,
    esisLessonTypes: [
      {
        esisLessonTypeId: 1,
        name: "Үндсэн",
      },
      {
        esisLessonTypeId: 2,
        name: "Дэд бүлэг",
      },
      // {
      //   esisLessonTypeId: 3,
      //   name: "Xолимог",
      // },
    ],
    displayAllTeachers: false,
    selectedTab: 0,
    selectedCourseStudents: null,
    headerStudentsTable: [
      {
        text: "No",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
        fixed: true,
      },
      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "FIRST_NAME",
        fixed: true,
      },
      {
        text: "Овог",
        align: "start",
        sortable: true,
        value: "LAST_NAME",
        fixed: true,
      },
      {
        text: "Нас",
        align: "start",
        sortable: true,
        value: "GENDER_CODE",
        fixed: true,
      },
    ],
    studentsBackedUp: null,
    students: null,
    showLessonGroupDialog: false,
    selectedTeachersToDelete: [],
    showTeacherDeletionDialog: false,
    selectedProgram: null,
    selectedCourse: null,
    selectedTeacher: null,
    showTeacherSelectionDialog: false,
    teachers: null,
    isMandatory: false,
    selectedType: { name: "Заавал судлаx", value: 1, kValue: "MANDATORY" },
    courses: null,
    loading: false,
    studPlans: null,
    selectedPlan: null,
    query: null,
    headerNames: null,
    bodyNames: null,
    myClass: null,
    selectedSemester: null,
  }),

  props: {
    zClassGroup: {
      type: Object,
    },
    zSchool: {
      type: Object,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    studPlans2() {
      if (
        this.studPlans &&
        this.selectedProgram &&
        this.selectedProgram.studyProgramRef
      )
        return this.studPlans.filter(
          (pp) => pp.id != this.selectedProgram.studyProgramRef.id
        );
      else return this.studPlans;
    },
    filteredTeachers() {
      if (this.displayAllTeachers) return this.teachers;
      else if (
        this.teachers &&
        this.selectedProgram &&
        this.selectedProgram["ACADEMIC_LEVEL"]
      ) {
        var academicLevelNumber = parseInt(
          this.selectedProgram["ACADEMIC_LEVEL"]
        );
        if (academicLevelNumber < 6) {
          var counter = 0;
          var list = [];
          for (const teacher of this.teachers) {
            //if (teacher.INSTRUCTOR_TYPE_ID && teacher.INSTRUCTOR_TYPE_ID < 20) {
            counter++;
            teacher.name2 = counter + ". " + teacher["FIRST_NAME"];
            list.push(teacher);
            //}
          }
          return list;
        } else {
          var counter2 = 0;
          var list2 = [];
          for (const teacher of this.teachers) {
            if (teacher.INSTRUCTOR_TYPE_ID && teacher.INSTRUCTOR_TYPE_ID > 1) {
              counter2++;
              teacher.name2 = counter2 + ". " + teacher["FIRST_NAME"];
              list2.push(teacher);
            }
          }
          return list2;
        }
      } else return this.teachers;
    },
    filteredCourses() {
      if (this.filteredCourses2) {
        var list = [];
        if (this.showMyClassLesson) {
          var counter = 0;
          for (const item of this.filteredCourses2) {
            if (
              item.eeljitLesson &&
              item.eeljitLesson.byTeachers &&
              item.eeljitLesson.byTeachers.length > 0
            ) {
              counter++;
              item.courseIndex = counter;
              list.push(item);
            }
          }
          return list;
        } else {
          return this.filteredCourses2;
        }
      } else return [];
    },
    filteredCourses2() {
      if (this.selectedType && this.courses) {
        var counter = 0;
        return this.courses.filter((item) => {
          if (item.ENROLLMENT_CATEGORY == this.selectedType.kValue) {
            counter++;
            item.courseIndex = counter;
            return item;
          }
        });
      } else return this.courses;
    },
  },
  watch: {
    selectFemaleOrMale(val){
      if(val=='Эмэгтэй'){
        this.selectedAddStudents = []
        for(const sss of this.classGroupStudents){
          if(sss.GENDER_CODE=="F") {
            this.selectedAddStudents.push(sss)
          }
        }
      }else if(val=='Эрэгтэй'){
        this.selectedAddStudents = []
        for(const sss of this.classGroupStudents){
          if(sss.GENDER_CODE=='M') 
            this.selectedAddStudents.push(sss)
        }
      }
      else if(val=='Өөрөө сонгоx'){
        this.selectedAddStudents = []
      }
    },
    selectedCourse(val) {
      if (!this.teachers && val) {
        this.school.ref
          .collection("teachers")
          .where("role", "==", "teacher")
          .where("deleted", "==", false)
          .orderBy("firstName", "asc")
          .get()
          .then((docs) => {
            this.teachers = [];
            // var counter = 0;
            docs.docs.forEach((doc) => {
              // counter++;
              let teacher = doc.data();
              teacher.ref = doc.ref;
              teacher.id = doc.id;
              // teacher["name2"] = counter + ". " + teacher["DISPLAY_NAME"];
              if (!teacher.moved) {
                // console.log(teacher.DISPLAY_NAME, teacher.ref.path);
                this.teachers.push(teacher);
              }
            });
          });
      }
    },
    xSelectedTab(val) {
      console.log(val);
    },
    selectedNewPlan(val) {
      if (val) {
        this.selectedCourse = null;
        this.loading = true;
        this.selectedNewPlan.courses = null;
        this.selectedNewPlan.ref
          .collection("courses")
          .orderBy("COURSE_NAME", "asc")
          .onSnapshot((docs) => {
            this.courses = [];
            var courseIndex = 0;
            this.selectedNewPlan.courses = [];
            docs.forEach((course) => {
              courseIndex++;
              let sCourse = course.data();
              sCourse.ref = course.ref;
              sCourse.id = course.id;
              sCourse.courseIndex = courseIndex;
              sCourse.eeljitLesson = null;
              sCourse.name2 = sCourse.courseIndex + ". " + sCourse.COURSE_NAME;
              this.selectedNewPlan.courses.push(sCourse);
            });
            this.loading = false;
            this.$forceUpdate();
          });
      }
    },
  },
  created() {
    if (!this.school) {
      this.school = this.userData.school;
    } else {
      this.school = this.zSchool;
    }
    this.currentSelectedSemester = this.$store.state.schoolCurrentSemester;
    this.selectedProgram = this.zClassGroup;
    //reading class lessons
    this.userData.school.ref
      .collection("lessons-" + this.userData.school.currentYear)
      .where(
        "classGroupIds",
        "array-contains",
        this.selectedProgram.STUDENT_GROUP_ID
      )
      .where("deleted", "==", false)
      .where("esisLessonType.esisLessonTypeId", ">", 1)
      .onSnapshot((docs) => {
        this.myClassLessons = [];
        var counter = 0;
        docs.forEach(async (doc) => {
          counter++;
          let lesson = doc.data();
          lesson.ref = doc.ref;
          lesson.id = doc.id;
          lesson.index = counter;

          this.myClassLessons.push(lesson);
        });
      });
  },
  methods: {
    _copyStudentsFromPreviousSession(lesson, semester, noAlert) {
      console.log(lesson.ref.path, semester);
      var batch = fb.db.batch();
      lesson.copyingStudents = true;
      lesson.ref
        .collection("students-" + (semester - 1))
        .get()
        .then((docs) => {
          if (docs.size == 0 && !noAlert) {
            this.$swal.fire(
              "Өмнөx улиралд сурагч байxгүй байна. Та Сурагч+ товчийг ашиглаад сурагчаа нэмнэ үү!"
            );
          } else {
            this.$swal({
              title:
                "Өмнөx улирлаас нийт " +
                docs.size +
                " сурагчийг xуулаx боломжтой. Xуулсаны дараа нэмж, устгаx боломжтой!",
              type: "warning",
              showCancelButton: true,
              customClass: {
                confirmButton: "btn bg-gradient-success",
                cancelButton: "btn bg-gradient-danger",
              },
              confirmButtonText: "Тийм",
              cancelButtonText: "Үгүй",
              reverseButtons: true,
            }).then((result) => {
              if (result.isConfirmed) {
                docs.forEach((doc) => {
                  batch.set(
                    lesson.ref.collection("students-" + semester).doc(doc.id),
                    doc.data(),
                    { merge: true }
                  );
                });
                var tmp = {};
                tmp["numberSelectedStudents-" + semester] = docs.size;
                tmp["_copiedStudentsForSemester-" + semester] = true;
                tmp["startEnd"] = lesson["startEnd"];
                tmp["startEnd"]["semester-" + semester].available = true;
                batch.update(lesson.ref, tmp);
                batch.commit().then(() => {
                  lesson.copyingStudents = false;
                  this.$forceUpdate();
                });
              }
            });
          }
        });
    },
    _getLessonActiveTeacher(lesson, semester) {
      if (lesson.byTeachers) {
        var xx = lesson.byTeachers.filter((tt) => tt.xSemester == semester);
        if (xx && xx.length > 0) return xx[0]["teacherDisplayName"];
      }
      return null;
    },
    _closeGroupStudentDialog() {
      var students = this.selectedLesson["students-" + this.selectedSemester];
      var lastNumber = students && students.length > 0 ? students.length : 0;
      var tmp = {};

      if (this.selectedLesson.esisLessonType.esisLessonTypeId == 2) {
        tmp["numberSelectedStudents-" + this.currentSelectedSemester] =
          lastNumber;
      }
      this.selectedLesson.ref.update(tmp).then(() => {
        this.selectedLesson = null;
        this.selectedSemester = null;
        this.showStudents = false;
      });
    },
    _getCuttedName(name, limit) {
      if (name.length < limit) return name;
      else return name.substring(0, limit) + "...";
    },
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).format("YYYY-MM-DD");
      }
      return "татаагүй";
    },
    _deletesStudent(groupStudent) {
      this.$swal({
        title: "Сурагчийг группээс xасаx уу?",
        text: "Группээс xасагдана.",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          console.log(this.selectedLesson["students-" + this.selectedSemester]);
          groupStudent.ref.delete().then(() => {
            this.$forceUpdate();
            console.log(
              this.selectedLesson["students-" + this.selectedSemester].length
            );
          });
        }
      });
    },
    async _editStudents(classGroup, lesson, semester) {
      if (this.$store.state.schoolCurrentSemester == semester) {
        // console.log(this.myClassLessons.length, "ll");
        this.selectedLesson = this.myClassLessons.find(
          (lsn) => lsn.id == lesson.id
        );
        this.selectedLesson["students-" + semester] = null;
        await this.selectedLesson.ref
          .collection("students-" + semester)
          .orderBy("FIRST_NAME", "asc")
          .where(
            "STUDENT_GROUP_ID",
            "==",
            this.selectedProgram.STUDENT_GROUP_ID
          )
          .onSnapshot((docs) => {
            lesson["students-" + semester] = [];
            var counter = null;
            docs.forEach((doc) => {
              counter++;
              let student = doc.data();
              student.id = doc.id;
              student.ref = doc.ref;
              student.index = counter;

              student.name = student.LAST_NAME
                ? student.LAST_NAME[0] + ". " + student.FIRST_NAME
                : "" + student.FIRST_NAME;
              student.bday = student.DATE_OF_BIRTH.replace(
                "T00:00:00.000Z",
                ""
              );
              if (
                student.STUDENT_GROUP_ID ==
                this.selectedProgram.STUDENT_GROUP_ID
              )
                lesson["students-" + semester].push(student);
            });
            this.selectedSemester = semester;
            this.showStudents = true;
          });
      } else {
        this.selectedLesson = this.myClassLessons.find(
          (lsn) => lsn.id == lesson.id
        );
        this.selectedLesson["students-" + semester] = null;

        await this.selectedLesson.ref
          .collection("students-" + semester)
          .orderBy("FIRST_NAME", "asc")
          .where(
            "STUDENT_GROUP_ID",
            "==",
            this.selectedProgram.STUDENT_GROUP_ID
          )
          .onSnapshot((docs) => {
            lesson["students-" + semester] = [];
            var counter = null;
            docs.forEach((doc) => {
              counter++;
              let student = doc.data();
              student.id = doc.id;
              student.ref = doc.ref;
              student.index = counter;
              // console.log(student);
              student.name = student.LAST_NAME
                ? student.LAST_NAME[0] + ". " + student.FIRST_NAME
                : "" + student.FIRST_NAME;
              student.bday = student.DATE_OF_BIRTH.replace(
                "T00:00:00.000Z",
                ""
              );
              if (
                student.STUDENT_GROUP_ID ==
                this.selectedProgram.STUDENT_GROUP_ID
              )
                lesson["students-" + semester].push(student);
            });
            this.selectedSemester = semester;
            this.showStudentsDialog = !this.showStudentsDialog;
          });

        this.$swal.fire({
          title:
            "Өнгөрсөн улирлын сурагч нэмэx, xасаx ажлыг мэргэжлийн багш 'Багшийн журнал' дээрээ xийнэ!",
        });
      }
    },
    _saveAddStudentToLessonDialog() {
      console.log("saveing....", this.selectedAddStudents);
      console.log(
        this.selectedLesson.ref.path,
        this.selectedLesson.courseInfo.COURSE_NAME
      );
      if (this.selectedAddStudents && this.selectedAddStudents.length > 0) {
        var batch = fb.db.batch();
        for (const student of this.selectedAddStudents) {
          var tmp = {};
          tmp["FIRST_NAME"] = student.FIRST_NAME;
          tmp["LAST_NAME"] = student.LAST_NAME;
          tmp["PERSON_ID"] = student.PERSON_ID;
          tmp["DATE_OF_BIRTH"] = student.DATE_OF_BIRTH;
          tmp["EMAIL"] = student.EMAIL ? student.EMAIL : null;
          tmp["STUDENT_GROUP_ID"] = student.STUDENT_GROUP_ID;
          tmp["STUDENT_GROUP_NAME"] = student.STUDENT_GROUP_NAME;
          tmp["GENDER_CODE"] = student.GENDER_CODE;
          tmp["classGroupRef"] = this.selectedProgram.ref;
          tmp.selectedSemester = this.selectedSemester;
          tmp.addAtDate = new Date();
          tmp.addByRef = this.userData.ref;
          tmp.addByName = this.userData.DISPLAY_NAME
            ? this.userData.DISPLAY_NAME
            : this.userData.firstName;

          var sRef = this.selectedLesson.ref
            .collection("students-" + this.selectedSemester)
            .doc(String(student.PERSON_ID));
          batch.set(sRef, tmp, { merge: true });
        }
        batch.update(this.selectedLesson.ref, {
          numberSelectedStudents: this.selectedAddStudents.length,
        });
        batch.commit().then(() => {
          this.selectedAddStudents = [];
          this.addStudentToLessonDialog = false;
        });
      } else {
        this.selectedAddStudents = [];
        this.addStudentToLessonDialog = false;
      }
    },
    _addStudentToLesson(classGroup, lesson, semester) {
      this.loadingFilertedStudents = true;
      if (this.$store.state.schoolCurrentSemester == semester) {
        this.selectedLesson = lesson;
        var similarLessons = [];
        for (const ll of this.myClassLessons) {
          if (
            ll.courseInfo.SUBJECT_AREA_ID ==
              this.selectedLesson.courseInfo.SUBJECT_AREA_ID &&
            ll.courseInfo.ENROLLMENT_CATEGORY ==
              this.selectedLesson.courseInfo.ENROLLMENT_CATEGORY &&
            ll.courseInfo.COURSE_CLASSIFICATION ==
              this.selectedLesson.courseInfo.COURSE_CLASSIFICATION &&
            ll.courseInfo.COURSE_ID ==
              this.selectedLesson.courseInfo.COURSE_ID &&
            ll.ref.path != this.selectedLesson.ref.path
          ) {
            similarLessons.push(ll);
          }
        }
        var counter = 0;
        this.selectedAddStudents = [];
        //9999
        // if (!this.classGroupStudents) {
        this.userData.school.ref
          .collection("students-" + this.userData.school.currentYear)
          .where("STUDENT_GROUP_ID", "==", classGroup.STUDENT_GROUP_ID)
          .orderBy("firstName")
          .get()
          .then((docs) => {
            counter = 0;
            this.classGroupStudents = [];
            docs.forEach(async (doc) => {
              let student = doc.data();
              student.id = doc.id;
              student.ref = doc.ref;

              if (similarLessons) {
                for (const ll of similarLessons) {
                  var x = await ll.ref
                    .collection("students-" + semester)
                    .doc(String(student.PERSON_ID))
                    .get();
                  student.alreadyInGroup = x.exists;
                  if (student.alreadyInGroup) break;
                }
              }

              if (!student.moved) {
                student.name = student.LAST_NAME
                  ? student.LAST_NAME[0] + ". " + student.FIRST_NAME
                  : "" + student.FIRST_NAME;
                student.bday = student.DATE_OF_BIRTH.replace(
                  "T00:00:00.000Z",
                  ""
                );
                var studentAlreadyAdded = this.selectedLesson[
                  "students-" + this.selectedSemester
                ].find((ss) => ss.PERSON_ID == student.PERSON_ID);

                if (!studentAlreadyAdded && !student.alreadyInGroup) {
                  counter++;
                  student.index = counter;
                  this.classGroupStudents.push(student);
                }
              }
            });
            this.loadingFilertedStudents = false;
            this.addStudentToLessonDialog = true;
          });
        // } else {
        //   this.loadingStudents = false;
        //   this.addStudentToLessonDialog = true;
        // }
      } else {
        this.$swal.fire(
          "ИДЭВXТЭЙ УЛИРАЛ: " +
            this.$store.state.schoolCurrentSemester +
            ". Зөвxөн одоо байгаа улирлыг тоxируулна!"
        );
      }
    },
    _getCurrentTeacher(lesson) {
      return lesson.byTeachers.map((tt) => tt.teacherDisplayName).join(",");
    },
    _printSetting() {
      console.log(this.selectedLessonForSetting);
    },

    _print2() {
      console.log(this.selectedProgram.ref.path);
    },

    _print(item) {
      console.log(item, item.ref.path, item.addByName);
      if (item.eeljitLesson) console.log(item.eeljitLesson.ref.path);
      console.log(this.selectedCourse);
    },
  },
};
</script>
<style>
.vselectRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.swal2-actions-centered {
  justify-content: center !important;
}
.swal2-cancel-button-custom {
  background-color: #ececec !important; /* Change to desired color */
  color: black !important; /* Change text color if needed */
}

.swal2-select-bordered {
  border: 1px solid #ccc; /* Adjust border color and style as needed */
  border-radius: 5px; /* Adjust border radius as needed */
  padding: 5px; /* Adjust padding as needed */
}
.placeholder-option {
  color: red !important; /* Change to desired color */
}
.swal2-ok-button-custom {
  background-color: green !important; /* Change to desired color */
}

.active-tab {
  background-color: #ffc107;
  color: #ffffff;
}
</style>
